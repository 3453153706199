import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  // --------------------------------------------------
  //                     PROPERTIES
  // --------------------------------------------------


  public form: {login: string, password: string} = {login: '', password: ''};
  public isSubmitted = false;
  public errorMessage = '';


  // --------------------------------------------------
  //                     CONSTRUCTOR
  // --------------------------------------------------


  constructor(
    private loginService: LoginService
  ) { }



  // --------------------------------------------------
  //                     METHODS
  // --------------------------------------------------

  /**
   * On init, instantiate login form builder.
   */
  ngOnInit() {}


  /** Login into the application */
  login() {
    this.isSubmitted = true;
    if (this.form.login && this.form.password) {
      this.loginService.login(this.form).subscribe(
        () => {
          this.isSubmitted = false;
          this.errorMessage = '';
        },
        error => {
          this.isSubmitted = false;
          this.errorMessage = 'APP.LOGIN.ERROR_MESSAGE';
        }
      );
    } else {
      this.isSubmitted = false;
      this.errorMessage = 'APP.LOGIN.MISSING_FIELD';
    }
  }
}
