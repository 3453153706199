import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Genart } from '../models/genart';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

const GENART_PATH = '/genarts';

@Injectable({
  providedIn: 'root'
})
export class GenartService {

  public genarts: Genart[] = [];
  public genartsSubject: BehaviorSubject<Genart[]> = new BehaviorSubject<Genart[]>([]);

  constructor(private http: HttpClient) {}

  getGenarts() {
    this.genarts = [
      {code: 1, label: 'Plaquettes avants'},
      {code: 2, label: 'Plaquettes arrières'},
      {code: 3, label: 'Niveau d\'huile'},
      {code: 4, label: 'Pression Pneuds'},
      {code: 5, label: 'Parallélisme'},
      {code: 6, label: 'Transmission'},
      {code: 7, label: 'Injecteurs'},
      {code: 8, label: 'Bougies'},
      {code: 9, label: 'Bloc optique'},
      {code: 10, label: 'Convecteur temporel'},
      {code: 11, label: 'Niveau de plutonium'},
    ];
    this.genartsSubject.next(this.genarts);
    // this.http.get(`${environment.baseUrl}${GENART_PATH}`).subscribe((data: any) => {
    //   this.genarts = [];
    //   if (data.objects && data.objects.length > 0) {
    //     data.objects.forEach((genartData) => {
    //       this.genarts.push(Genart.mapToGenart(genartData));
    //     });
    //   }
    //   this.genartsSubject.next(this.genarts);
    // });
  }

  getGenartsSubject() {
    this.getGenarts();
    return this.genartsSubject.asObservable();
  }
}
