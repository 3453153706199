import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from './toast.service';
import { LoginService } from './login.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private loginService: LoginService, private toastService: ToastService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError((err) => {
          console.error(err);
          if (err.status === 401) {
            this.loginService.logout();
            this.toastService.setToast('ERRORS.401', 'warning');
          } else if (err.status === 400) {
            this.toastService.setToast('ERRORS.400', 'error');
          } else if (err.status === 404) {
            this.toastService.setToast('ERRORS.404', 'error');
          } else {
            this.toastService.setToast('ERRORS.GENERIC', 'error');
          }
          return throwError(err);
      }));
    }
}
