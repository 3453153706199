import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-roles-selection',
  templateUrl: './roles-selection.component.html',
  styleUrls: ['./roles-selection.component.scss']
})
export class RolesSelectionComponent implements OnInit {


  // --------------------------------------------------
  //                     PROPERTIES
  // --------------------------------------------------

  public isSubmitted = false;

  public roles: Array<{
    user: User,
    label: string
  }> = [
    {
      user: {
        id: 0,
        firstname: '',
        lastname: 'Admin central',
        email: '',
        role: 'CENTRAL_ADMIN'
      },
      label: 'Admin central'
    },
    {
      user: {
        id: 1,
        firstname: '',
        lastname: 'Admin zone',
        email: '',
        role: 'ZONE_ADMIN'
      },
      label: 'Admin zone'
    }
  ];

  public selectedRole: {user: User, label: string};

  public errorMessage: string;

  // --------------------------------------------------
  //                     CONSTRUCTOR
  // --------------------------------------------------


  constructor(private loginService: LoginService,
              private userService: UserService) {}



  // --------------------------------------------------
  //                     METHODS
  // --------------------------------------------------

  /**
   * On init, instantiate login form builder.
   */
  ngOnInit() {}

  selectRole() {
    this.isSubmitted = true;
    if (this.selectedRole) {
      this.loginService.setTestRole(this.selectedRole.user);
      this.isSubmitted = false;
      this.errorMessage = 'APP.ROLES.MISSING_FIELD';
    } else {
      this.isSubmitted = false;
      this.userService.user = this.selectedRole.user;
      this.errorMessage = 'APP.ROLES.MISSING_FIELD';
    }
  }
}
