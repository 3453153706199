import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/internal/operators/tap';
import { UserService } from './user.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // Todo: set to false
  public isLogged = true;
  public logging = false;
  public attemptedRoute: string;

  constructor(private router: Router, private userService: UserService) {}

  init(url: string) {
    if (url) {
      this.attemptedRoute = url;
    }
    if (this.logging) {
      return false;
    }
    if (this.isLogged) {
      return true;
    }
    // Todo: check token
    this.logout();
    return false;
  }

  setTestRole(user: User) {
    this.isLogged = true;
    this.userService.setUser(user);
    if (this.attemptedRoute) {
      this.router.navigate(this.attemptedRoute.split('/'));
    }
  }

  login(loginForm: {login: string, password: string}): Observable<void> {
    this.logging = true;
    return new Observable<void>((observer => {
      setTimeout(() => {
        // observer.error();
        // this.isLogged = false;
        this.isLogged = true;
        this.logging = false;
        observer.next();
        observer.complete();
      }, 200);
    })).pipe(
      tap((data) => {
        // Todo set user returned or get user if not.
        this.userService.setUser({
          id: 0,
          firstname: '',
          lastname: loginForm.login,
          email: '',
          role: 'CENTRAL_ADMIN'
        });
        if (this.attemptedRoute) {
          this.router.navigate(this.attemptedRoute.split('/'));
        }
      })
    );
  }

  logout() {
    this.isLogged = false;
    this.logging = false;
  }
}
