import { Injectable } from '@angular/core';
import { Prestation } from '../models/prestation';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { Operation } from '../models/operation';

const PRESTATIONS_PATH = '/product';

@Injectable({
  providedIn: 'root'
})
export class PrestationsService {

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  getPrestations(search?: string, page?: number) {
    return this.http.get(`${environment.baseUrl}${PRESTATIONS_PATH}${this.utilsService.buildQueryParams({search, page})}`).pipe(
      map((data: any) => {
        const prestations: Array<Prestation> = [];
        data.objects.forEach((object) => {
          prestations.push(Prestation.mapToPrestation(object));
        });
        return {total: data.totalCount, prestations};
      })
    );
  }

  addLine(newLine: {labelFr: string, labelEn: string, labelEs: string, reference: string, brrNumber: number, id?: number}) {
    const body = {
      labelFr: newLine.labelFr,
      labelEn: newLine.labelEn,
      labelEs: newLine.labelEs,
      ref: newLine.reference,
      brrNumber: newLine.brrNumber
    };

    return this.http.post(`${environment.baseUrl}${PRESTATIONS_PATH}`, body).pipe(
      map((data: any) => {
        return Prestation.mapToPrestation(data);
      })
    );
  }

  setLine(newLine: {labelFr: string, labelEn: string, labelEs: string, reference: string, brrNumber: number, id?: number}) {
    const body = {
      labelFr: newLine.labelFr,
      labelEn: newLine.labelEn,
      labelEs: newLine.labelEs,
      ref: newLine.reference,
      brrNumber: newLine.brrNumber
    };
    return this.http.put(`${environment.baseUrl}${PRESTATIONS_PATH}/${newLine.id}`, body).pipe(
      map((data: any) => {
        return Prestation.mapToPrestation(data);
      })
    );
  }

  removePrestation(prestationId: number) {
    return this.http.delete(`${environment.baseUrl}${PRESTATIONS_PATH}/${prestationId}`);
  }

  getOperationsIds(operations: Array<Operation>) {
    const operationsIds = [];
    operations.forEach((operation: Operation) => {
      operationsIds.push(operation.id);
    });
    return operationsIds;
  }

  addOperation(prestation: Prestation, operation: Operation) {
    const operationsIds = this.getOperationsIds(prestation.operations);
    const alreadyIn = operationsIds.findIndex((id) => id === operation.id) > -1;
    if (!alreadyIn) {
      operationsIds.push(operation.id);
    }
    return this.http.patch(`${environment.baseUrl}${PRESTATIONS_PATH}/${prestation.id}`, operationsIds);
  }

  removeOperation(prestation: Prestation, operation: Operation) {
    const operationsIds = this.getOperationsIds(prestation.operations);
    const index = operationsIds.findIndex((id) => id === operation.id);
    if (index > -1) {
      operationsIds.splice(index, 1);
    }
    return this.http.patch(`${environment.baseUrl}${PRESTATIONS_PATH}/${prestation.id}`, operationsIds);
  }
}
