import { Component } from '@angular/core';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent {

  public tabs: Array<{label: string, paths: string[]}> = [
    { label: 'APP.CONFIGURATION.COUNTRIES_TAB', paths: ['countries'] },
    { label: 'APP.CONFIGURATION.PRESTATIONS_TAB', paths: ['prestations'] },
    { label: 'APP.CONFIGURATION.OPERATIONS_TAB', paths: ['operations'] },
    { label: 'APP.CONFIGURATION.MAPPING', paths: ['mapping'] },
    { label: 'APP.CONFIGURATION.RULES', paths: ['rules'] }
  ];
}
