import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridDescriptor } from '../../core/models/grid/grid-descriptor';
import { Operation, OPTYPES } from '../../core/models/operation';
import { OperationsService } from './services/operations.service';
import { Observable } from 'rxjs';
import { ModalDescriptor } from '../../core/models/modal/modal-descriptor';
import { GenartService } from '../../core/services/genart.service';
import { Genart } from '../../core/models/genart';

@Component({
  selector: 'app-operations-grid',
  templateUrl: './operations-grid.component.html',
  styleUrls: ['./operations-grid.component.scss']
})
export class OperationsGridComponent implements OnInit, OnDestroy {
  public descriptor: GridDescriptor;

  public newLineStructure: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: {value: string, label: string}
    revision: boolean,
    brrNumber: number,
    genarts: Genart[]
  } = {
    code: '',
    labelFr: '',
    labelEn: '',
    labelEs: '',
    type: {value: '', label: ''},
    revision: false,
    brrNumber: undefined,
    genarts: []
  };

  public operations: Array<Operation> = [];

  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));

  public searchText = '';
  private searchTimeout: any;

  public totalItem = 0;
  public loadingPage = false;
  private lastPage = 0;

  public genarts: Genart[] = [];
  public allGenarts: Genart[] = [];
  public genartsSearchText: string;
  private genartsSubscription: any;

  constructor(private operationsService: OperationsService, private genartService: GenartService) {}

  ngOnInit() {
    this.genartsSubscription = this.genartService.getGenartsSubject().subscribe(
      (genarts: Genart[]) => {
        this.allGenarts = genarts;
        this.filterGenart(this.genartsSearchText);
      }
    );
    this.getOperations(0);
  }

  ngOnDestroy() {
    this.genartsSubscription.unsubscribe();
  }

  searchChanged() {
    if (this.searchTimeout) {
      this.searchTimeout.cancel();
    }
    this.searchTimeout = setTimeout(() => {
      this.getOperations(0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.operations.length < this.totalItem) {
      this.getOperations(this.lastPage + 1);
    }
  }

  getOperations(page?: number) {
    this.loadingPage = true;
    this.operationsService.getOperations(this.searchText, page).subscribe(
      (result: {total: number, operations: Array<Operation>}) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.operations) {
          if (page === 0) {
            this.setOperations(result.operations);
          } else {
            this.setOperations([...this.operations, ...result.operations]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setOperations(operations: Array<Operation>) {
    this.operations = operations;
  }

  setDescriptor() {
    this.descriptor = {
      columns: [
        {
          id: 1,
          label: 'APP.OPERATIONS_GRID.CODE',
          labelParameters: {},
          value: 'code',
          type: 'text',
          required: true,
          weight: '2'
        },
        {
          id: 2,
          label: 'APP.OPERATIONS_GRID.LABEL_FR',
          labelParameters: {},
          value: 'labelFr',
          type: 'text',
          required: true,
          weight: '3'
        },
        {
          id: 3,
          label: 'APP.OPERATIONS_GRID.LABEL_EN',
          labelParameters: {},
          value: 'labelEn',
          type: 'text',
          required: true,
          weight: '3'
        },
        {
          id: 4,
          label: 'APP.OPERATIONS_GRID.LABEL_ES',
          labelParameters: {},
          value: 'labelEs',
          type: 'text',
          required: true,
          weight: '3'
        },
        {
          id: 5,
          label: 'APP.OPERATIONS_GRID.BRR_NUMBER',
          labelParameters: {},
          value: 'brrNumber',
          type: 'number',
          required: true,
          weight: '2'
        },
        {
          id: 6,
          label: 'APP.OPERATIONS_GRID.GENART',
          labelParameters: {},
          value: 'genarts',
          type: 'selected-chips',
          required: false,
          weight: '4',
          selectDescriptor: {
            options: this.genarts,
            model: {name: 'label', value: ''},
            searchChangeBehavior: this.filterGenart.bind(this)
          },
          chipsIdentifier: 'code'
        },
        {
          id: 7,
          label: 'APP.OPERATIONS_GRID.OPTYPE',
          labelParameters: {},
          value: 'type.value',
          display: 'type.label',
          type: 'select',
          required: true,
          weight: '3',
          selectDescriptor: {
            options: OPTYPES,
            model: {name: 'label', value: ''}
          }
        },
        {
          id: 8,
          label: 'APP.OPERATIONS_GRID.REVISION',
          labelParameters: {},
          value: 'revision',
          display: 'revision',
          type: 'checkbox',
          required: false,
          weight: '1',
        }
      ],
      actions: [
        {
          label: 'remove',
          tooltip: 'APP.OPERATIONS_GRID.REMOVE_LINE_TOOLTIP',
          icon: 'fa-trash error-button',
          behavior: this.removeOperation.bind(this),
          confirmation: true,
          parameters: []
        }
      ],
      actionWeight: '1',
      newLine: true
    };
  }

  addLine(newLine: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: {value: {value: string, label: string}, label: string},
    revision: boolean,
    brrNumber: number,
    genarts: Genart[],
    id?: number
  }) {
    if (newLine.id) {
      this.operationsService.setLine(newLine).subscribe(
        (operation: Operation) => {
          const index = this.operations.findIndex((d) => d.id === operation.id);
          if (index > -1) {
            this.operations[index] = operation;
          } else {
            this.operations.push(operation);
          }
        }
      );
    } else {
      this.operationsService.addLine(newLine).subscribe(
        (newOperation: Operation) => {
          this.operations.push(newOperation);
        }
      );
    }
  }

  closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  openRemoveModal(operation: Operation) {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.OPERATIONS_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.OPERATIONS_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }

  removeOperation(operationId: number) {
    const index = this.operations.findIndex((operation) => operation.id === operationId);
    if (index > -1) {
      this.openRemoveModal(this.operations[index]).subscribe(
        (success: boolean) => {
          if (success) {
            this.operationsService.removeOperation(operationId).subscribe(
              (data) => {
                this.getOperations(0);
              }
            );
          }
        }
      );
    }
  }

  filterGenart(searchText: string) {
    if (searchText) {
      this.genartsSearchText = searchText;
      this.genarts = this.allGenarts.filter((genart: Genart) => {
        return genart.label.toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      this.genarts = this.allGenarts;
    }
    if (this.descriptor) {
      this.descriptor.columns[5].selectDescriptor.options = this.genarts;
    } else {
      this.setDescriptor();
    }
  }
}
