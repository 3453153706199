import { Operation } from './operation';

export class Prestation {

  constructor( public id?: number,
               public labelFr?: string,
               public labelEn?: string,
               public labelEs?: string,
               public reference?: string,
               public operations?: Array<Operation>
  ) {}

  static mapToPrestation(data) {
    const operations: Array<Operation> = [];

    if (data.operations && data.operations.length > 0) {
      data.operations.forEach((operation: Operation) => {
        operations.push(Operation.mapToOperation(operation));
      });
    }

    return new Prestation(
      data.id,
      data.labelFr,
      data.labelEn,
      data.labelEs,
      data.ref,
      operations
    );
  }

  static mapFromPrestation(prestation: Prestation) {
    return {
      id: prestation.id,
      labelFr: prestation.labelFr,
      labelEn: prestation.labelEn,
      labelEs: prestation.labelEs,
      ref: prestation.reference
    };
  }
}
