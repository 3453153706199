import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { I18nService } from './i18n.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private i18nService: I18nService) {}

  intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    // const token = this.connexionService.getToken();
    // if (token) {
      const authRequest = request.clone({
        headers: new HttpHeaders({
          // Authorization: `Bearer ${token}`
          'Accept-Language': this.i18nService.lang
        })
      });
      return httpHandler.handle(authRequest);
    // }
    // return httpHandler.handle(request);
  }
}
