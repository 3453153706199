import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  public tabs = [
    {path: ['standard-grid'], label: 'APP.NAVIGATION.STANDARD_GRID'},
    {path: ['promotion-grid'], label: 'APP.NAVIGATION.PROMOTION_GRID'},
    {path: ['discount-grid'], label: 'APP.NAVIGATION.DISCOUNT_GRID'}
  ];

  constructor(private userService: UserService) {}

  ngOnInit() {
    // if (this.userService.user.role === 'CENTRAL_ADMIN') {
      this.tabs.push({path: ['configuration'], label: 'APP.NAVIGATION.CONFIGURATION'});
    // }
  }
}
