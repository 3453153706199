import { Price } from './price';

export class Recommendation {

  constructor( public id?: number,
               public reference?: string,
               public label?: string,
               public prices?: Array<Price>
  ) {}

  static mapToRecommendation(data: any) {
    const prices: Array<Price> = [];
    if (data.priceNatPlanList) {
      data.priceNatPlanList.forEach((price) => {
        prices.push(Price.mapToPrice(price));
      });
    }

    return new Recommendation(
      data.id,
      data.ref,
      data.label,
      prices
    );
  }
}
