import { Component, OnInit } from '@angular/core';
import { GridDescriptor } from '../core/models/grid/grid-descriptor';
import { Country } from '../core/models/country';
import { CountriesService } from '../core/services/countries.service';
import { Recommendation } from '../core/models/recommendation';
import { RecommendationService } from './services/recommendation.service';
import { ToastService } from '../core/services/toast.service';

@Component({
  selector: 'app-standard-grid',
  templateUrl: './standard-grid.component.html',
  styleUrls: ['./standard-grid.component.scss']
})
export class StandardGridComponent implements OnInit {
  public originalRecommendations: Array<Recommendation> = [];
  public recommendations: Array<{
    id: number,
    reference: string,
    label: string,
    prices: {
      [key: string]: number
    }
  }> = [];
  public descriptor: GridDescriptor;

  public countries: Array<Country> = [];
  public selectedCountry: Country;

  public searchText = '';
  private searchTimeout: any;

  public totalItem = 0;
  public loadingPage = false;
  private lastPage = 0;

  constructor(private countriesService: CountriesService,
              private toastService: ToastService,
              private recommendationService: RecommendationService) {}

  ngOnInit() {
    this.getCountries();
  }

  searchChanged() {
    if (this.searchTimeout) {
      this.searchTimeout.cancel();
    }
    this.searchTimeout = setTimeout(() => {
      this.getRecommendations(this.selectedCountry, 0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.recommendations.length < this.totalItem) {
      this.getRecommendations(this.selectedCountry, this.lastPage + 1);
    }
  }

  getCountries(searchText?: string) {
    this.countriesService.getCountries(searchText).subscribe(
      (results: {total: number, countries: Array<Country>}) => {
        if (results.countries && results.countries.length > 0) {
          this.setCountries(results.countries);
        }
      }
    );
  }

  setCountries(countries: Array<Country>) {
    this.countries = countries;
    if (this.countriesService.countryId) {
      const country = this.countries.find((c: Country) => c.id === this.countriesService.countryId);
      if (country) {
        this.onCountryChange(country);
      }
    }
  }

  onCountryChange(country: Country) {
    this.countriesService.getCountry(country.id).subscribe(
      (fullCountry: Country) => {
        this.selectedCountry = fullCountry;
        if (this.countriesService.countryId !== country.id) {
          this.countriesService.setCountry(fullCountry.id);
        }
        this.getRecommendations(fullCountry, 0);
      }
    );
  }

  getRecommendations(country: Country, page?: number) {
    this.loadingPage = true;
    this.recommendationService.getRecommendations(this.selectedCountry, this.searchText, page).subscribe(
      (result: {total: number, recommendations: Array<Recommendation>}) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.recommendations) {
          if (page === 0) {
            this.recommendations = [];
            this.setRecommendations(result.recommendations);
            this.setDescriptor();
          } else {
            this.setRecommendations([...this.originalRecommendations, ...result.recommendations]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setDescriptor() {
    this.descriptor = {
      columns: [
        {
          id: 1,
          label: 'APP.STANDARD_GRID.ID',
          labelParameters: {},
          value: 'reference',
          type: 'text'
        },
        {
          id: 2,
          label: 'APP.STANDARD_GRID.LABEL',
          labelParameters: {},
          value: 'label',
          type: 'text'
        }
      ],
      actions: [],
      newLine: false
    };

    this.selectedCountry.listArea.forEach((area, index) => {
      this.descriptor.columns.push({
        id: index + 2,
        label: 'APP.STANDARD_GRID.PRICE_ZONE',
        labelParameters: {area: area.label},
        value: `prices.${area.label}`,
        type: 'number'
        // suffix: this.selectedCountry.currency
      });
    });
  }

  setRecommendations(recommendations: Array<Recommendation>) {
    this.originalRecommendations = recommendations;
    recommendations.forEach((recommendation) => {
      if (recommendation && recommendation.reference && recommendation.prices.length > 0) {
        this.setRecommendation(recommendation);
      } else {
        this.totalItem = this.totalItem - 1;
      }
    });
  }

  setRecommendation(recommendation: Recommendation) {
    const recommendationPrices = {};
    recommendation.prices.forEach((price) => {
      recommendationPrices[price.zone] = price.price;
    });

    const index = this.recommendations.findIndex((r) => r.reference === recommendation.reference);
    if (index > -1) {
      this.recommendations[index] = {
        id: recommendation.id,
        reference: recommendation.reference,
        label: recommendation.label,
        prices: recommendationPrices
      };
    } else {
      this.recommendations.push({
        id: recommendation.id,
        reference: recommendation.reference,
        label: recommendation.label,
        prices: recommendationPrices
      });
    }
  }

  importGrid(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.recommendationService.import(file, this.selectedCountry).subscribe(
        (data) => {
          this.toastService.setToast('APP.STANDARD_GRID.UPLOAD_SUCCESSFUL', 'success');
          this.getRecommendations(this.selectedCountry, 0);
        }
      );
    }
  }

  exportGrid() {
    this.recommendationService.export(this.selectedCountry).subscribe();
  }
}
