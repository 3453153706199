import { Component } from '@angular/core';
import { LoginService } from './core/services/login.service';
import { I18nService } from './core/services/i18n.service';
import { environment } from '../environments/environment';
import { ToastService } from './core/services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public sideMenuShown = true;

  public environmentTest = environment.testMode;

  constructor(private i18nService: I18nService,
              public toastService: ToastService,
              public loginService: LoginService) {
    this.i18nService.setLocale('fr');
  }
}
