import { Component, Input, OnInit } from '@angular/core';
import { DiscountService } from './services/discount.service';
import { Discount } from '../core/models/discount';
import { GridDescriptor } from '../core/models/grid/grid-descriptor';
import { Country } from '../core/models/country';
import { CountriesService } from '../core/services/countries.service';
import { Observable } from 'rxjs';
import { ModalDescriptor } from '../core/models/modal/modal-descriptor';
import { PrestationsService } from '../core/services/prestations.service';
import { Prestation } from '../core/models/prestation';
import { I18nService } from '../core/services/i18n.service';

@Component({
  selector: 'app-discount-grid',
  templateUrl: './discount-grid.component.html',
  styleUrls: ['./discount-grid.component.scss']
})
export class DiscountGridComponent implements OnInit {

  public discounts: Array<Discount> = [];
  public descriptor: GridDescriptor;

  public newLineStructure: Discount = {
    prestation: undefined,
    rate: undefined,
    startDate: undefined,
    endDate: undefined
  };

  public countries: Array<Country> = [];
  public prestations: Array<Prestation> = [];
  public selectedCountry: Country;

  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));

  public searchText = '';
  private searchTimeout: any;

  public totalItem = 0;
  public loadingPage = false;
  private lastPage = 0;

  private lang = 'En';

  constructor(private discountService: DiscountService,
              private prestationsService: PrestationsService,
              private i18nService: I18nService,
              private countriesService: CountriesService) {}

  ngOnInit() {
    this.getCountries();
    this.getPrestations();
    this.i18nService.getLang().subscribe(
      (lang: string) => {
        this.lang = lang.charAt(0).toUpperCase() + lang.substr(1).toLowerCase();
        if (this.descriptor && this.descriptor.columns && this.descriptor.columns[0]) {
          this.descriptor.columns[0].display = `prestation.label${this.lang}`;
          if (this.descriptor.columns[0].selectDescriptor && this.descriptor.columns[0].selectDescriptor.model) {
            this.descriptor.columns[0].selectDescriptor.model.name = `label${this.lang}`;
          }
        }
      }
    );
  }

  searchChanged() {
    if (this.searchTimeout) {
      this.searchTimeout.cancel();
    }
    this.searchTimeout = setTimeout(() => {
      this.getDiscounts(this.selectedCountry, 0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.discounts.length < this.totalItem) {
      this.getDiscounts(this.selectedCountry, this.lastPage + 1);
    }
  }

  getPrestations(searchText?: string) {
    this.prestationsService.getPrestations(searchText, 0).subscribe(
      (results: {total: number, prestations: Array<Prestation>}) => {
        if (results.prestations && results.prestations.length > 0) {
          this.setPrestations(results.prestations);
        }
      }
    );
  }

  setPrestations(prestations: Array<Prestation>) {
    this.prestations = prestations;
    if (!this.descriptor) {
      this.setDescriptor();
    } else {
      this.descriptor.columns[0].selectDescriptor.options = this.prestations;
    }
  }

  getCountries(searchText?: string) {
    this.countriesService.getCountries(searchText).subscribe(
      (result: {total: number, countries: Array<Country>}) => {
        if (result.countries && result.countries.length > 0) {
          this.setCountries(result.countries);
        }
      }
    );
  }

  setCountries(countries: Array<Country>) {
    this.countries = countries;
    if (this.countriesService.countryId) {
      this.selectedCountry = this.countries.find((country: Country) => country.id === this.countriesService.countryId);
      if (this.selectedCountry) {
        this.getDiscounts(this.selectedCountry, 0);
      }
    }
  }

  onCountryChange(country: Country) {
    this.selectedCountry = country;
    this.countriesService.setCountry(country.id);
    this.getDiscounts(country, 0);
  }

  getDiscounts(country: Country, page?: number) {
    this.loadingPage = true;
    this.discountService.getDiscounts(country, this.searchText, page).subscribe(
      (result: {total: number, discounts: Array<Discount>}) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.discounts) {
          if (page === 0) {
            this.setDiscounts(result.discounts);
          } else {
            this.setDiscounts([...this.discounts, ...result.discounts]);
          }
        }
        this.loadingPage = false;
      }
    );
  }


  setDiscounts(discounts: Array<Discount>) {
    this.discounts = discounts;
  }

  setDescriptor() {
    this.descriptor = {
      columns: [
        {
          id: 1,
          label: 'APP.DISCOUNT_GRID.PRESTATION',
          labelParameters: {},
          value: 'prestation',
          display: `prestation.label${this.lang}`,
          type: 'select',
          required: true,
          weight: '4',
          selectDescriptor: {
            options: this.prestations,
            model: {name: `label${this.lang}`, value: ''},
            searchChangeBehavior: this.getPrestations.bind(this)
          }
        },
        {
          id: 2,
          label: 'APP.DISCOUNT_GRID.RATE',
          labelParameters: {},
          value: 'rate',
          type: 'number',
          suffix: '%',
          required: true,
          weight: '1',
          fieldValidator: {
            isValid: this.rateValidation,
            validationParams: [],
            linkedValidation: [],
            message: 'APP.DISCOUNT_GRID.ERRORS.RATE'
          }
        },
        {
          id: 3,
          label: 'APP.DISCOUNT_GRID.START',
          labelParameters: {},
          value: 'startDate',
          type: 'date',
          required: true,
          weight: '3',
          fieldValidator: {
            isValid: this.dateValidation,
            validationParams: [],
            linkedValidation: [4],
            message: 'APP.DISCOUNT_GRID.ERRORS.START_DATE'
          }
        },
        {
          id: 4,
          label: 'APP.DISCOUNT_GRID.END',
          labelParameters: {},
          value: 'endDate',
          type: 'date',
          required: true,
          weight: '3',
          fieldValidator: {
            isValid: this.dateValidation,
            validationParams: [],
            linkedValidation: [3],
            message: 'APP.DISCOUNT_GRID.ERRORS.END_DATE'
          }
        }
      ],
      actions: [
        {
          label: 'remove',
          tooltip: 'APP.DISCOUNT_GRID.REMOVE_LINE_TOOLTIP',
          icon: 'fa-trash error-button',
          behavior: this.removeDiscount.bind(this),
          confirmation: true,
          parameters: []
        }
      ],
      actionWeight: '1',
      newLine: true
    };
  }

  addLine(newLine: Discount) {
    if (newLine.id) {
      this.discountService.setLine(newLine, this.selectedCountry).subscribe(
        (discount: Discount) => {
          const index = this.discounts.findIndex((d) => d.id === discount.id);
          if (index > -1) {
            this.discounts[index] = discount;
          } else {
            this.discounts.push(discount);
          }
        }
      );
    } else {
      this.discountService.addLine(newLine, this.selectedCountry).subscribe(
        (newDiscount: Discount) => {
          this.discounts.push(newDiscount);
        }
      );
    }
  }

  closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  openRemoveModal(discount: Discount) {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.DISCOUNT_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.DISCOUNT_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }

  removeDiscount(discountId: number) {
    const index = this.discounts.findIndex((discount) => discount.id === discountId);
    if (index > -1) {
      this.openRemoveModal(this.discounts[index]).subscribe(
        (success: boolean) => {
          if (success) {
            this.discountService.removeDiscount(discountId).subscribe(
              () => {
                this.getDiscounts(this.selectedCountry, 0);
              }
            );
          }
        }
      );
    }
  }

  dateValidation(property: any, line: {prestation: Prestation, rate: number, startDate: Date, endDate: Date, id?: number}): boolean {
    return !line.startDate || !line.endDate || new Date(line.startDate) <= new Date(line.endDate);
  }

  rateValidation(property: any, line: {prestation: Prestation, rate: number, startDate: Date, endDate: Date, id?: number}): boolean {
    return line.rate % 1 === 0;
  }
}
