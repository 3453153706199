import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/services/auth-guard.service';
import { StandardGridComponent } from './standard-grid/standard-grid.component';
import { PromotionGridComponent } from './promotion-grid/promotion-grid.component';
import { DiscountGridComponent } from './discount-grid/discount-grid.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { CountriesGridComponent } from './configuration/countries-grid/countries-grid.component';
import { PrestationsGridComponent } from './configuration/prestations-grid/prestations-grid.component';
import { OperationsGridComponent } from './configuration/operations-grid/operations-grid.component';
import { MappingComponent } from './configuration/mapping/mapping.component';
import { RulesComponent } from './configuration/rules/rules.component';

const routes: Routes = [
  { path: '', redirectTo: 'standard-grid', pathMatch: 'full' },
  { path: 'standard-grid', component: StandardGridComponent, canActivate: [AuthGuardService] },
  { path: 'promotion-grid', component: PromotionGridComponent, canActivate: [AuthGuardService] },
  { path: 'discount-grid', component: DiscountGridComponent, canActivate: [AuthGuardService] },
  { path: 'configuration',
    component: ConfigurationComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'countries', pathMatch: 'full' },
      { path: 'countries', component: CountriesGridComponent, canActivate: [AuthGuardService] },
      { path: 'prestations', component: PrestationsGridComponent, canActivate: [AuthGuardService] },
      { path: 'operations', component: OperationsGridComponent, canActivate: [AuthGuardService] },
      { path: 'mapping', component: MappingComponent, canActivate: [AuthGuardService] },
      { path: 'rules', component: RulesComponent, canActivate: [AuthGuardService] }
    ]
  },
  { path: '**', redirectTo: 'standard-grid', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
