import { Component, OnInit } from '@angular/core';
import { PrestationsService } from '../../core/services/prestations.service';
import { OperationsService } from '../operations-grid/services/operations.service';
import { Prestation } from '../../core/models/prestation';
import { Operation } from '../../core/models/operation';
import { I18nService } from '../../core/services/i18n.service';

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss']
})
export class MappingComponent implements OnInit {

  public searchText: string;
  public operations: Array<Operation> = [];
  // public operations: Array<Operation> = [
  //   {id: 1, label: 'bbb'},
  //   {id: 2, label: 'bbo'},
  //   {id: 3, label: 'boo'},
  //   {id: 4, label: 'bob'},
  //   {id: 5, label: 'ooo'},
  //   {id: 6, label: 'oob'},
  //   {id: 7, label: 'obb'},
  //   {id: 8, label: 'obo'}
  // ];
  public prestations: Array<Prestation> = [];
  // public prestations: Array<Prestation> = [
  //   {id: 12, label: 'aaa', reference: 'ccc', operations: [{id: 1, label: 'bbb'}, {id: 2, label: 'bbo'}]},
  //   {id: 22, label: 'aak', reference: 'ccd', operations: [{id: 7, label: 'obb'}]},
  //   {id: 32, label: 'akk', reference: 'cdd', operations: [{id: 3, label: 'boo'}, {id: 4, label: 'bob'}]},
  //   { id: 42,
  //     label: 'aka',
  //     reference: 'cdc',
  //     operations: [
  //       {id: 1, label: 'bbb'},
  //       {id: 2, label: 'bbo'},
  //       {id: 3, label: 'boo'},
  //       {id: 4, label: 'bob'},
  //       {id: 5, label: 'ooo'}
  //     ]},
  //   {id: 52, label: 'kkk', reference: 'ddd', operations: []},
  //   {id: 62, label: 'kka', reference: 'ddc', operations: []},
  //   {id: 72, label: 'kaa', reference: 'dcc', operations: []},
  //   {id: 82, label: 'kak', reference: 'dcd', operations: []},
  // ];
  public  loadingPage = false;
  private lastPage = 0;
  private totalItem = 0;
  private searchTimeout: any;

  public lang = 'En';

  public selectedOperations: {[prestationId: string]: Operation} = {};
  public openedPrestation: {[prestationId: string]: boolean} = {
    12: true,
    22: false,
    32: false,
    42: false,
    52: false,
    62: false,
    72: false,
    82: false
  };

  constructor(private prestationsService: PrestationsService,
              private operationsService: OperationsService,
              private i18nService: I18nService) {}

  ngOnInit() {
    this.getPrestations(0);
    this.getOperations();
    this.i18nService.getLang().subscribe(
      (lang: string) => {
        this.lang = lang.charAt(0).toUpperCase() + lang.substr(1).toLowerCase();
      }
    );
  }

  searchChanged() {
    if (this.searchTimeout) {
      this.searchTimeout.cancel();
    }
    this.searchTimeout = setTimeout(() => {
      this.getPrestations(0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.prestations.length < this.totalItem) {
      this.getPrestations(this.lastPage + 1);
    }
  }

  getPrestations(page?: number) {
    this.loadingPage = true;
    this.prestationsService.getPrestations(this.searchText, page).subscribe(
      (result: {total: number, prestations: Array<Prestation>}) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.prestations && result.prestations.length > 0) {
          if (page === 0) {
            this.setPrestations(result.prestations);
          } else {
            this.setPrestations([...this.prestations, ...result.prestations]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setPrestations(prestations: Array<Prestation>) {
    this.prestations = prestations;
    this.setOpenedPrestations();
  }

  setOpenedPrestations() {
    this.prestations.forEach((prestation) => {
      if (!this.openedPrestation[prestation.id]) {
        this.openedPrestation[prestation.id] = false;
      }
    });
  }

  getOperations(searchText?: string) {
    this.operationsService.getOperations(searchText, 0).subscribe(
      (results: {total: number, operations: Array<Operation>}) => {
        this.setOperations(results.operations);
      }
    );
  }

  setOperations(operations: Array<Operation>) {
    this.operations = [];
    operations.forEach((operation) => {
      if (!operation.prestationId) {
        this.operations.push(operation);
      }
    });
  }

  addOperation(prestation: Prestation) {
    if (this.selectedOperations[prestation.id]) {
      this.prestationsService.addOperation(prestation, this.selectedOperations[prestation.id]).subscribe(
        () => {
          prestation.operations.push(this.selectedOperations[prestation.id]);
          this.getOperations();
        }
      );

    }
  }

  removeOperation(prestation: Prestation, operation: Operation) {
    this.prestationsService.removeOperation(prestation, operation).subscribe(
      () => {
        const index = prestation.operations.findIndex((o) => operation.id === o.id);
        prestation.operations.splice(index, 1);
        this.getOperations();
      }
    );
  }
}
