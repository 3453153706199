import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../../../core/services/utils.service';
import { map } from 'rxjs/operators';
import { Operation } from '../../../core/models/operation';
import { Genart } from '../../../core/models/genart';

const OPERATIONS_PATH = '/operations';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  getOperations(search?: string, page?: number) {
    const url = `${environment.baseUrl}${OPERATIONS_PATH}${this.utilsService.buildQueryParams({search, page})}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        const operations: Array<Operation> = [];
        data.objects.forEach((item) => {
          operations.push(Operation.mapToOperation(item));
        });
        return {total: data.totalCount, operations: operations};
      })
    );
  }

  addLine(newLine: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: {value: {value: string, label: string}, label: string},
    revision: boolean,
    brrNumber: number,
    genarts: Genart[],
    id?: number
  }) {
    const body = {
      code: newLine.code,
      labelFr: newLine.labelFr,
      labelEn: newLine.labelEn,
      labelEs: newLine.labelEs,
      operationEnum: newLine && newLine.type && newLine.type. value ? newLine.type.value.value : undefined,
      revision: newLine.revision,
      brrNumber: newLine.brrNumber,
      genarts: newLine.genarts
    };
    return this.http.post(`${environment.baseUrl}${OPERATIONS_PATH}`, body);
  }

  setLine(newLine: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: {value: {value: string, label: string}, label: string},
    revision: boolean,
    brrNumber: number,
    genarts: Genart[],
    id?: number
  }) {
    const body = {
      id: newLine.id,
      code: newLine.code,
      labelFr: newLine.labelFr,
      labelEn: newLine.labelEn,
      labelEs: newLine.labelEs,
      operationEnum: newLine.type.value.value,
      revision: newLine.revision,
      brrNumber: newLine.brrNumber,
      genarts: newLine.genarts
    };
    return this.http.put(`${environment.baseUrl}${OPERATIONS_PATH}`, body);
  }

  removeOperation(operationId: number) {
    return this.http.delete(`${environment.baseUrl}${OPERATIONS_PATH}/${operationId}`);
  }
}
