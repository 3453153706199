import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { ConfigurationComponent } from './configuration.component';
import { CountriesGridComponent } from './countries-grid/countries-grid.component';
import { PrestationsGridComponent } from './prestations-grid/prestations-grid.component';
import { OperationsGridComponent } from './operations-grid/operations-grid.component';
import { OperationsService } from './operations-grid/services/operations.service';
import { MappingComponent } from './mapping/mapping.component';
import { RulesService } from './rules/services/rules.service';
import { RulesComponent } from './rules/rules.component';

@NgModule({
  declarations: [
    ConfigurationComponent,
    CountriesGridComponent,
    PrestationsGridComponent,
    OperationsGridComponent,
    RulesComponent,
    MappingComponent
  ],
  imports: [
    CoreModule,
  ],
  providers: [
    OperationsService,
    RulesService
  ]
})
export class ConfigurationModule { }
