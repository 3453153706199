import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { I18nService } from '../services/i18n.service';

/**
 * Values of an InputsDate.
 */
interface InputValues {
  order: number;
  value: string;
  previous?: string;
  next?: string;
}

/**
 *  Values of date picker inputs.
 */
interface InputsDate {
  day: InputValues;
  month: InputValues;
  year: InputValues;
}

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {

  // --------------------------------------------------
  //                     PROPERTIES
  // --------------------------------------------------

  public inputsDate: InputsDate = {
    day: undefined,
    month: undefined,
    year: undefined
  };

  public selectedDay: Subject<{selectedDay: Moment, name?: string}> = new Subject<{selectedDay: Moment, name?: string}>();
  public fieldsOrder: BehaviorSubject<any> = new BehaviorSubject<any>(this.getUnitsOrder());

  public dateSplitter: string;


  // --------------------------------------------------
  //                     CONSTRUCTOR
  // --------------------------------------------------

  /**
   *  On init, Set order of Date Picker inputs with default locale format.
   */
  constructor(private i18nService: I18nService) {
    this.i18nService.getLang().subscribe(
      (lang: string) => {
        this.inputsDate = this.getInputsWithLocaleFormat(lang);
        this.fieldsOrder.next(this.getUnitsOrder());
        // this.selectedDay.next({selectedDay: this.getDateMomentFormat(), name});
      }
    );
  }


  // --------------------------------------------------
  //                     METHODS
  // --------------------------------------------------

  /**
   * Get the character which split the date and split actual moment to get units of date format in order.
   * Returns inputs of date picker sort by lang format past in argument, with their previous and next inputs references.
   */
  getInputsWithLocaleFormat(lang?: string): InputsDate {
    // moment.locale(lang);
    this.dateSplitter = moment.localeData().longDateFormat('L').indexOf('/') > -1 ? '/'
        : (moment.localeData().longDateFormat('L').indexOf('-') > -1 ? '-' : '.');

    const dateFormat = moment.localeData().longDateFormat('L').split(this.dateSplitter);

    const inputs: InputsDate = {
      day: undefined, month: undefined, year: undefined
    };

    dateFormat.forEach((unit, index) => {

      // if it isn't the first unit, get the previous input.
      let previous: string;
      if (index > 0) {
        const keys = Object.keys(inputs);
        const values = Object.values(inputs);
        previous = keys[values.findIndex((value: any) => value && value.order === (index - 1))];
      }

      // if unit represents day.
      if (unit === 'DD' || unit === 'D') {
        inputs.day = { order: index, value: undefined };
        if (previous) {
          inputs.day.previous = previous;
          inputs[previous].next = 'day';
        }

      // if unit represents month.
      } else if (unit === 'MM' || unit === 'M') {
        inputs.month = { order: index, value: undefined };
        if (previous) {
          inputs.month.previous = previous;
          inputs[previous].next = 'month';
        }

      // if unit represents year.
      } else if (unit === 'YYYY') {
        inputs.year = { order: index, value: undefined };
        if (previous) {
            inputs.year.previous = previous;
            inputs[previous].next = 'year';
        }
      }
    });
    return inputs;
  }

  /**
   * Notify fields order change.
   */
  getFieldsOrder(): Observable<any> {
    return this.fieldsOrder.asObservable();
  }

  /**
   * Returns selected day.
   */
  getSelectedDay(): Observable<{selectedDay: Moment, name?: string}> {
    return this.selectedDay.asObservable();
  }


  /**
   * Set the selected day by datepicker inputs or by calendar.
   */
  setSelectedDay(date?: Moment, name?: string): void {
      if (date) {
          this.inputsDate.day.value = date.format('DD');
          this.inputsDate.month.value = date.format('MM');
          this.inputsDate.year.value = date.format('YYYY');
      }
      this.selectedDay.next({selectedDay: this.getDateMomentFormat(), name});
  }

  /**
   * Set day, month and year values.
   */
  setDateValues(day: string, month: string, year: string): void {
    this.inputsDate.day.value = day;
    this.inputsDate.month.value = month;
    this.inputsDate.year.value = year;
  }


  /**
   * Returns actual date in Moment format.
   */
  getDateMomentFormat(): Moment {
    const orderedValues = [];
    let actualUnit: any;
    for (actualUnit in this.inputsDate) {
      if (actualUnit) {
        orderedValues[this.inputsDate[actualUnit].order] = this.inputsDate[actualUnit].value;
      }
    }
    return moment(Object.values(orderedValues).join(this.dateSplitter), 'L');
  }

  /**
   * Returns if date is valid.
   */
  dateMomentValidator(): boolean {
    return this.getDateMomentFormat().isValid();
  }

  /**
   * Return if date picker inputs are correctly filled.
   */
  isInputsFilled(): boolean {
    return this.inputsDate.day.value && this.inputsDate.month.value
        && this.inputsDate.year.value && (String(this.inputsDate.year.value).length === 4);
  }

  /**
   * Returns if date picker is invalid.
   */
  isDatePickerInvalid(): boolean {
    return (this.isInputsFilled() && !this.dateMomentValidator());
  }

  /**
   * Returns the character which split date.
   */
  getDateSplitter(): string {
    return this.dateSplitter;
  }

  /**
   * Returns the name of the previous input for a unit.
   */
  getPreviousInput(unit: string): string {
    return this.inputsDate[unit].previous;
  }

  /**
   * Returns the name of the next input for a unit.
   */
  getNextInput(unit: string): string {
    return this.inputsDate[unit].next;
  }

  /**
   * Retruns the order of day, month and year.
   */
  getUnitsOrder(): any {
    if (this.inputsDate.day && this.inputsDate.month && this.inputsDate.year) {
      return {
        day: this.inputsDate.day.order,
        month: this.inputsDate.month.order,
        year: this.inputsDate.year.order
      };
    }
  }
}
